import { EnvLocale, LangCd } from 'hems';
import { defineComponent } from 'vue';

export function getTermAndCondition(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./${locale}/${termsVer}/${langCd}/TermAndCondition.vue`);
}

export function getOptionalAgree(locale: EnvLocale, termsVer: string, langCd: LangCd): Promise<typeof defineComponent> {
  return import(`./${locale}/${termsVer}/${langCd}/OptionalAgree.vue`);
}

export function getPrivacyPolicy(locale: EnvLocale, termsVer: string, langCd: LangCd): Promise<typeof defineComponent> {
  return import(`./${locale}/${termsVer}/${langCd}/PrivacyPolicy.vue`);
}

export function getGDPR(locale: EnvLocale, termsVer: string, langCd: LangCd): Promise<typeof defineComponent> {
  return import(`./${locale}/${termsVer}/${langCd}/GDPR.vue`);
}

export function getPrivacyPolicyGen2(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./gen2/${locale}/${termsVer}/${langCd}/PrivacyPolicy.vue`);
}

export function getTermsOfUseGen2(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./gen2/${locale}/${termsVer}/${langCd}/TermsOfUse.vue`);
}

export function getTransferCrossBordersGen2(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./gen2/${locale}/${termsVer}/${langCd}/TransferCrossBorders.vue`);
}

export function getIndividualUsageGen2(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./gen2/${locale}/${termsVer}/${langCd}/IndividualUsage.vue`);
}

export function getDirectMarketingGen2(
  locale: EnvLocale,
  termsVer: string,
  langCd: LangCd
): Promise<typeof defineComponent> {
  return import(`./gen2/${locale}/${termsVer}/${langCd}/DirectMarketing.vue`);
}
