
import { defineComponent, defineAsyncComponent, reactive, computed } from 'vue';
import { AppCtxState } from '@/store/modules/appCtx';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { useStore } from 'vuex';
import { Selector } from '@hems/component';
import { Constant } from '@hems/util';

const termsVerAU = process.env.VUE_APP_TERMS_VER_AU || '';
const PrivacyAUEN = defineAsyncComponent(() => getPrivacyPolicy('AU', termsVerAU, 'en'));

export default defineComponent({
  name: 'AUTermsAndConditionPage',
  components: {
    Selector,
    PrivacyAUEN,
  },
  setup(props, { emit }) {
    const store = useStore();
    const appCtx = store.state.appCtx as AppCtxState;
    const langOptionsAU = Constant.languages.filter((item) => item.value === 'en');
    const state = reactive({
      region: 'AU',
      lang: 'en',
      langOptions: langOptionsAU,
    });

    const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);

    return {
      state,
      componentName,
    };
  },
});
